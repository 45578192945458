.search-input .ant-input-affix-wrapper {
  height: 30px;
  border-right: none;
}

.search-input .ant-input-group-addon button {
  height: 30px !important;
  border: 1px solid;
  border-left: none;
}
