.project-tooltip .ant-tooltip-inner {
  background: linear-gradient(95.36deg, rgba(35, 47, 106, 0.7) 7.46%, rgba(35, 47, 106, 0.4) 102.31%);
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.07em;
  color: #ffffff;
  box-shadow: 0 4px 4px rgba(111, 111, 111, 0.3);
  backdrop-filter: blur(2px);
  border-radius: 4px;
}

.useful-info-tooltip .ant-tooltip-inner {
  padding: 24px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.67);
  box-shadow: 0px 10px 10px 0px rgba(65, 65, 65, 0.2);
  backdrop-filter: blur(7px);
  width: 331px;
}

.useful-info-tooltip .ant-tooltip-inner .useful-info-text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.98px;
}
