@import url('https://fonts.googleapis.com/css?family=Rajdhani');

body {
  margin: 0;
  font-family: Rajdhani, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scroll-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll-container::-webkit-scrollbar {
  width: 4px; /* Adjust as needed */
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Scrollbar thumb color */
  border-radius: 2px; /* Rounded corners for the thumb */
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Darker color on hover */
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
  background-color: #F9F9F9;
  padding: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 3.5px;
  padding: 5px;
  width: 12px;
}

.help-image.ant-image-preview-root .ant-image-preview-img{
  max-height: 95%;
  width: 95%;
}
.help-image.ant-image{
  width: 100%;
}
#msdoc-renderer {
  height: calc(91vh - 100px);
}