.project-popover-action,
.long-text {
  background: linear-gradient(122.32deg, rgba(237, 239, 248, 0.9) 3.09%, rgba(237, 239, 248, 0.4) 99.26%);
  border: 1px solid #ffffff;
  box-shadow: 0px 10px 10px rgba(141, 143, 166, 0.2);
  backdrop-filter: blur(7px);
  padding: 0px;
}

.project-popover-action .ant-popover-arrow,
.perspective-popover .ant-popover-arrow,
.project-popover-node-type .ant-popover-arrow,
.project-popover-manage-property .ant-popover-arrow,
.project-popover-color .ant-popover-arrow,
.long-text .ant-popover-arrow {
  display: none;
}

.project-popover-action .ant-popover-inner {
  background-color: transparent;
  padding: 0;
}

.project-popover-action .ant-popover-inner .ant-popover-title {
  background-color: transparent;
  margin-bottom: 0;
  padding: 12px 9px 12px 4px;
  background: linear-gradient(92.68deg, rgba(220, 223, 244, 0.7) 0%, rgba(220, 220, 244, 0.2) 98.48%);
  box-shadow: 0px 4px 4px rgba(157, 162, 198, 0.2);
  backdrop-filter: blur(5px);
}

.project-popover-action .ant-popover-inner {
  background-color: transparent;
}

.long-text {
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ffffff;
}

.long-text .ant-popover-inner,
.project-popover-node-type .ant-popover-inner {
  background: transparent;
  padding: 5px 10px;
}

.long-text .ant-popover-inner .ant-popover-title {
  margin-bottom: 0;
}

.project-popover-node-type,
.project-popover-manage-property {
  background: #f2f2f2;
  box-shadow: 0px 20px 20px rgba(111, 111, 111, 0.3);
  border-radius: 4px;
  padding: 0;
  top: 142px !important;
}

.project-popover-jira-project .ant-popover-inner {
  background-color: transparent;
  padding: 0;
}

.project-popover-jira-project .ant-popover-content {
  background: white;
}

.project-popover-color .ant-popover-inner {
  background-color: transparent;
  padding: 0;
  width: 220px;
}

.perspective-popover .ant-popover-inner {
  width: 180px;
  height: 80px;
  background: linear-gradient(122.32deg, rgba(237, 239, 248, 0.9) 3.09%, rgba(237, 239, 248, 0.4) 99.26%);
}

.perspective-popover .ant-popover-inner .ant-popover-inner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  align-items: end;
}
