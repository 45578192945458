.project-menu-action .ant-menu {
    background: linear-gradient(122.32deg, rgba(237, 239, 248, 0.9) 3.09%, rgba(237, 239, 248, 0.4) 99.26%);
    border: 1px solid #FFFFFF;
    box-shadow: 0px 10px 10px rgba(141, 143, 166, 0.2);
    backdrop-filter: blur(7px);
    padding-left: 0px;
    width: 261px;
}

.project-menu-action .ant-menu .ant-menu-item {
    margin: 4px 0;
    width: 100%;
}

.project-menu-action .ant-menu .ant-menu-item:hover, .project-menu-action .ant-menu .ant-menu-item:active {
    background: linear-gradient(90deg, rgba(35, 47, 106, 0.2) 0%, rgba(35, 47, 106, 0.2) 100%);
    padding: 0 16px;
}

.project-menu-action .ant-menu .ant-menu-item .anticon-folder {
    color: #C3C3C3;
}

.project-menu-action .ant-menu .ant-menu-item:hover .anticon-folder,
.project-menu-action .ant-menu .ant-menu-item:active .anticon-folder {
    color: #232F6A;
}

.project-menu-action .ant-menu .ant-menu-item:hover path,
.project-menu-action .ant-menu .ant-menu-item:active path {
    fill: #232F6A;
}