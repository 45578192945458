.edge-type-select {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem !important;
}

.add-edge-drawer .ant-select-selection-placeholder {
  color: #232f6a !important;
}

.add-edge-drawer .ant-select-selector {
  align-items: center;
}

.edge-type-select .ant-select-selector {
  background: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.add-edge-drawer {
  z-index: 1;
}

.add-edge-drawer .ant-drawer-mask {
  z-index: 0;
}

.add-edge-drawer .ant-drawer-header {
  border-top: 6px solid #f0f0f0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  background: #f2f2f2;
  box-shadow: 0px 10px 10px 0px rgba(111, 111, 111, 0.1);
}
