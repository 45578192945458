.profile-settings > .ant-dropdown-menu {
  background: linear-gradient(122.32deg, rgba(237, 239, 248, 0.9) 3.09%, #edeff8 99.26%);
  border: 1px solid #ffffff;
  box-shadow: 0 10px 10px rgba(141, 143, 166, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  width: 288px;
  height: 120px;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.profile-settings .ant-dropdown-menu-item {
  width: 90%;
  height: 3rem;
  transition: none !important;
}

.profile-settings .ant-dropdown-menu-title-content a {
  transition: none !important;
}

.profile-settings .ant-dropdown-menu-item:hover {
  backdrop-filter: blur(1px);
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.profile-settings .ant-dropdown-menu-item:hover path {
  stroke: #000000;
  stroke-width: 1.5px;
}

.profile-settings .ant-dropdown-menu-item-divider {
  margin: 4px 0;
  width: 100%;
}
