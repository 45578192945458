.scroll-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.scroll-container::-webkit-scrollbar {
  width: 4px; /* Adjust as needed */
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Scrollbar thumb color */
  border-radius: 2px; /* Rounded corners for the thumb */
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.5); /* Darker color on hover */
}

.ql-mention-list-container {
  background-color: #fff;
  border-radius: 4px;
  z-index: 1000;
}

.ql-mention-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.ql-mention-list-item {
  font-size: 16px;
  padding: 12px 32px;
}

.ql-mention-list-item.selected {
  background-color: #dbdbdb;
  cursor: pointer;
}

.comment-description p {
  margin: 0;
}

.comment-description .mention {
  color: #232f6a;
}
